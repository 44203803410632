import { Injectable, inject } from "@angular/core";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { AuthHttpClient } from "../auth/auth-client.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { DocType } from "@libs/shares/dto/getUser.dto";

@Injectable({
  providedIn: "root"
})
export class ConsentService {
  cookieService = inject(CookieService);
  gtmService = inject(GoogleTagManagerService);

  constructor(private authHttpClient: AuthHttpClient) {}

  acceptTos(documentType: DocType[]) {
    return this.authHttpClient.post<Promise<void>>(`${environment.apiBaseUrl}/users/consentManegement`, {
      documentType,
      consentDate: new Date()
    });
  }

  hasAcceptedCookies() {
    return this.cookieService.get("cookieConsent") === "allow";
  }

  hasRejectedCookies() {
    return this.cookieService.get("cookieConsent") === "deny";
  }
  updateConsent = (consentStatus: "granted" | "denied") => {
    this.gtmService.pushTag({
      event: "consent_update",
      consentStatus: consentStatus
    });
  };
  acceptCookies(cookieValue: "allow" | "deny") {
    this.cookieService.set("cookieConsent", cookieValue, {
      domain: environment.cookieDomain,
      expires: 365
    });
    if (cookieValue === "allow") {
      this.updateConsent("granted");
    } else {
      this.updateConsent("denied");
    }
  }

  updateCommercialDocsConsent(orgId: string, subscriptionId: bigint, consentGiven: boolean, topUpId?: bigint) {
    const filterBy = topUpId ? { topUpId } : { subscriptionId };
    return this.authHttpClient.post<Promise<void>>(`${environment.apiBaseUrl}/legal-docs/${orgId}`, {
      ...filterBy,
      consentGiven
    });
  }
}
